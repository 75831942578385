import React, { useEffect, useState, useCallback, useRef } from "react";
import ScrollMagic from "scrollmagic";
import gsap from "gsap";
import { Modal } from 'react-bootstrap';
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import GLightbox from "glightbox";
import YouTubePlayer from 'youtube-player';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';

import Logo from '../assets/logo.png';
import BkgMain from "../assets/main_hero.jpg";
import BkgImageStory from "../assets/story_bkg.jpg";
import BkgImageWishlist from "../assets/wish_bkg.jpg";
import CenterDividerSvg from '../assets/center_divider.svg';
import CenterDivider2Svg from '../assets/center_divider_2.svg';
import BkgImageWish from '../assets/wishlist_logos.png';
import DividerBar from '../assets/divider.png';
import HeroSideBar from '../assets/hero_sidebars.png';
import StorySideBar from '../assets/story_sidebars.png';
import WishSideBar from '../assets/wishlist_sidebars.png';
import BkgImageSign from '../assets/sign_bkg.jpg';
import BarLine from '../assets/bar_line.svg';
import FBLogo from '../assets/facebook.svg';
import TWLogo from '../assets/twitter.svg';
import ThreadLogo from '../assets/thread.svg';
import IGLogo from '../assets/instagram.svg';
import DCLogo from '../assets/discord.svg';
import CMLogo from '../assets/community.svg';
import RedLogo from '../assets/reddit.svg';
import SwiperArrow from '../assets/slider_nav.png';

import { SteamLogo } from '../components/SteamLogo';
import { EpicLogo } from '../components/EpicLogo';
import { PSLogo } from '../components/PSLogo';
import { XBoxLogo } from '../components/XBoxLogo';

ScrollMagicPluginGsap(ScrollMagic, gsap);

export function Home() {
    const firstEffectRan = useRef(false)
    const [showTrailer, setShowTrailer] = useState(false);
    const [showAgeGate, setShowAgeGate] = useState(false);
    const [gateError, setGateError] = useState(false);
    const [email, setEmail] = useState('');
    const { t } = useTranslation();
    const altTitle = t("settings.alt");
    const mailChimpUrl = "https://tripwireinteractive.us2.list-manage.com/subscribe/post?u=0b638f09c7c45666e46052ede&id=b8bfd5aafe";
    const mediaAssets = t("media.assets", {
        returnObjects: true
    });
    const HandleOnChange = useCallback((event) => {
        const { value } = event.currentTarget;
        setEmail(value);
    }, []);
    const HandleShowTrailer = useCallback((e) => {
        e.preventDefault();
        setShowTrailer(true);
    }, []);
    const PlayTrailer = () => {
        const player = YouTubePlayer('player');
        player.loadVideoById(t('hero.trailer'));
        player.playVideo();

    };
    const HandleCloseTrailer = () => setShowTrailer(false);
    const ValidateAge = () => {
        let fields = [];
        const ageGate = document.querySelectorAll('.age-gate');
        ageGate.forEach(input => {
            fields.push(input.value);
        });
        let today = new Date();
        let birthDate = new Date(fields[2], fields[0], fields[1]);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        if (age > 17) {
            setShowAgeGate(false);
            SetCookie('agegate_allow');
            setGateError(false);
            document.body.setAttribute('style', 'overflow:inherit;');
        } else {
            setGateError(true);
        }
    };
    const SetCookie = (cname) => {
        const d = new Date();
        d.setTime(d.getTime() + (60 * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=accept;" + expires + ";path=/";
    };
    const GetCookie = (cname) => {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    };
    useEffect(() => {
        const introBlock = document.getElementById("intro");
        const LoadScroll = () => {
            const scrollController = new ScrollMagic.Controller();
            const tweenVideoHero = gsap.fromTo("#video-hero", 1, { scale: 1 }, { scale: 1.1 });
            const tweenStoryBkg = gsap.to("#story-zoom", { duration: 1, scale: 1.5, ease: "none" });
            const tweenWishBkg = gsap.to("#wishlist-zoom", { duration: 1, scale: 1.5, ease: "none" });
            const tweenSignBkg = gsap.to("#signup-zoom", { duration: 1, scale: 1.5, ease: "none" });
            const tweenBar1 = gsap.to("#bar-1", { duration: 0.3, scale: 1.2, opacity: 1, x: 0, ease: "none" });
            const tweenBar2 = gsap.to("#bar-2", { duration: 0.3, scale: 1.2, opacity: 1, ease: "none" });
            const tweenBar3 = gsap.to("#bar-3", { duration: 0.3, scale: 1.2, opacity: 1, x: 0, ease: "none" });
            GLightbox({ moreLength: 0 });
            // Allow AgeGate
            if (!GetCookie('agegate_allow')) {
                document.body.setAttribute('style', 'overflow:hidden;');
                window.scrollTo(0, 0);
                setShowAgeGate(true);
            } else {
                setShowAgeGate(false);
            }
            // Intro  
            new ScrollMagic.Scene({ triggerElement: '#intro', triggerHook: 0, duration: '100%' })
                .setClassToggle("header", "active")
                .setTween(tweenVideoHero)
                .addTo(scrollController);
            new ScrollMagic.Scene({ triggerElement: '#intro', triggerHook: 0, duration: '100%' })
                .setClassToggle("#intro .inview", "fadeInUp")
                .addTo(scrollController);
            //  Story Block
            new ScrollMagic.Scene({ triggerElement: '#story', triggerHook: 0.7, duration: '100%' })
                .setTween(tweenStoryBkg)
                .setClassToggle("#story .inview", "fadeInUp")
                .addTo(scrollController)
                .on('start', function () {
                    introBlock.classList.add("is-scrolled");
                })
                .on('leave', function () {
                    introBlock.classList.remove("is-scrolled");
                });
            new ScrollMagic.Scene({ triggerElement: '#story', triggerHook: 1, duration: '100%' })
                .setTween(tweenBar1)
                .addTo(scrollController);
            new ScrollMagic.Scene({ triggerElement: '#story', triggerHook: 1, duration: '100%' })
                .setTween(tweenBar2)
                .addTo(scrollController);
            new ScrollMagic.Scene({ triggerElement: '#story', triggerHook: 1, duration: '100%' })
                .setTween(tweenBar3)
                .addTo(scrollController);
            // Media
            new ScrollMagic.Scene({ triggerElement: '#media', triggerHook: 0.7, duration: '100%' })
                .setClassToggle("#media .inview", "fadeInUp")
                .addTo(scrollController);
            // Wishlist
            new ScrollMagic.Scene({ triggerElement: '#wishlist', triggerHook: 0.7, duration: '100%' })
                .setClassToggle("#wishlist .inview", "fadeInUp")
                .setTween(tweenWishBkg)
                .addTo(scrollController);
            // Signup
            new ScrollMagic.Scene({ triggerElement: '#signup', triggerHook: 0.7, duration: '100%' })
                .setClassToggle("#signup .inview", "fadeInUp")
                .setTween(tweenSignBkg)
                .addTo(scrollController);
        }
        if (process.env.NODE_ENV === 'development') {
            if (firstEffectRan.current) {
                LoadScroll();
            }
            return () => {
                firstEffectRan.current = true
            }
        } else {
            LoadScroll();
        }
    }, [showAgeGate]);
    return (
        <>
            {showAgeGate && (
                <section id="agegate">
                    <div className="h-100 d-flex justify-content-center align-items-center">
                        <div className="wrapper">
                            <h3 className="title mb-4">{t("agegate.title")}</h3>
                            <form>
                                <div className="form-group d-flex justify-content-between">
                                    <input type="number" inputMode="numeric" pattern="[0-9]{3}" name="MM" placeholder="MM" min="01" max="12" maxLength="2" className="form-control age-gate" required />
                                    <input type="number" inputMode="numeric" pattern="[0-9]{3}" name="DD" placeholder="DD" min="01" max="31" maxLength="2" className="form-control age-gate" required />
                                    <input type="number" inputMode="numeric" pattern="[0-9]{3}" name="YYYY" placeholder="YYYY" max="9999" maxLength="4" className="form-control age-gate" required />
                                </div>
                                {gateError && (
                                    <div className="alert alert-danger">{t("agegate.error")}</div>
                                )}
                                <button type="button" className="btn btn-shape" onClick={(event) => ValidateAge(event)}>Enter</button>
                            </form>
                            <div className="note"><p dangerouslySetInnerHTML={{ __html: t('agegate.note') }} /></div>
                        </div>
                    </div>
                </section>
            )}
            <header>
                <div className="container-fluid d-flex justify-content-between">
                    <a href="/" className="brand-logo"><img src={Logo} className="img-fluid" alt="logo" /></a>
                    <div className="social-logo">
                        <a href={t('social.facebook')} target="_blank" rel="noopener noreferrer" title="Facebook"><svg xmlns="http://www.w3.org/2000/svg" width="113px" height="100px" viewBox="0 0 113 100">
                            <path fillRule="evenodd" fill="#c51e1e" d="M84.750,0.000 L113.000,50.000 L84.750,100.000 L28.250,100.000 L0.000,50.000 L28.250,0.000 L84.750,0.000 Z" /></svg>
                            <img className="img-fluid" src={FBLogo} alt="Facebook" /></a>
                        <a href={t('social.twitter')} target="_blank" rel="noopener noreferrer" title="Twitter"><svg xmlns="http://www.w3.org/2000/svg" width="113px" height="100px" viewBox="0 0 113 100">
                            <path fillRule="evenodd" fill="#c51e1e" d="M84.750,0.000 L113.000,50.000 L84.750,100.000 L28.250,100.000 L0.000,50.000 L28.250,0.000 L84.750,0.000 Z" /></svg><img className="img-fluid" src={TWLogo} alt="Twitter" /></a>
                        <a href={t('social.discord')} target="_blank" rel="noopener noreferrer" title="Discord"><svg xmlns="http://www.w3.org/2000/svg" width="113px" height="100px" viewBox="0 0 113 100">
                            <path fillRule="evenodd" fill="#c51e1e" d="M84.750,0.000 L113.000,50.000 L84.750,100.000 L28.250,100.000 L0.000,50.000 L28.250,0.000 L84.750,0.000 Z" /></svg><img className="img-fluid" src={DCLogo} alt="Discord" /></a>
                        <a href={t('social.thread')} target="_blank" rel="noopener noreferrer" title="Thread"><svg xmlns="http://www.w3.org/2000/svg" width="113px" height="100px" viewBox="0 0 113 100">
                            <path fillRule="evenodd" fill="#c51e1e" d="M84.750,0.000 L113.000,50.000 L84.750,100.000 L28.250,100.000 L0.000,50.000 L28.250,0.000 L84.750,0.000 Z" /></svg><img className="img-fluid" src={ThreadLogo} alt="Discord" /></a>
                        <a href={t('social.instagram')} target="_blank" rel="noopener noreferrer" title="Instagram"><svg xmlns="http://www.w3.org/2000/svg" width="113px" height="100px" viewBox="0 0 113 100">
                            <path fillRule="evenodd" fill="#c51e1e" d="M84.750,0.000 L113.000,50.000 L84.750,100.000 L28.250,100.000 L0.000,50.000 L28.250,0.000 L84.750,0.000 Z" /></svg><img className="img-fluid" src={IGLogo} alt="Instagram" /></a>
                        <a href={t('social.community')} target="_blank" rel="noopener noreferrer" title="Community"><svg xmlns="http://www.w3.org/2000/svg" width="113px" height="100px" viewBox="0 0 113 100">
                            <path fillRule="evenodd" fill="#c51e1e" d="M84.750,0.000 L113.000,50.000 L84.750,100.000 L28.250,100.000 L0.000,50.000 L28.250,0.000 L84.750,0.000 Z" /></svg><img className="img-fluid" src={CMLogo} alt="Community" /></a>
                        <a href={t('social.reddit')} target="_blank" rel="noopener noreferrer" title="Reddit"><svg xmlns="http://www.w3.org/2000/svg" width="113px" height="100px" viewBox="0 0 113 100">
                            <path fillRule="evenodd" fill="#c51e1e" d="M84.750,0.000 L113.000,50.000 L84.750,100.000 L28.250,100.000 L0.000,50.000 L28.250,0.000 L84.750,0.000 Z" /></svg><img className="img-fluid" src={RedLogo} alt="Community" /></a>
                    </div>
                </div>
            </header>
            <section id="intro" style={{ backgroundImage: `url(${BkgMain})` }}>
                <div className="video-wrapper">
                    <iframe id="video-hero" title={altTitle} src={`https://player.vimeo.com/video/${t('hero.video')}?api=1&background=1&autoplay=1&loop=1&byline=0&title=0`} width="100%" height="100%" allowFullScreen="" allow="autoplay" data-ready="true"></iframe>
                </div>
                <div className="bars-wrapper">
                    <img className="sidebar" src={HeroSideBar} alt={altTitle} />
                    <img className="sidebar" src={HeroSideBar} alt={altTitle} />
                </div>
                <div className="d-flex align-items-end justify-content-center h-100">
                    <div className="layer w-100">
                        <div className="container">
                            <h1 className="heading inview animated delay-1">{t('hero.title')}</h1>
                            <div className="center-bar my-4 inview animated delay-2">
                                <img className="img-fluid" src={CenterDividerSvg} alt={altTitle} />
                            </div>
                            <div className="action-buttons my-3 inview animated delay-4">
                                <a className="svg-btn" href="#trailer" title="Play Trailer" onClick={HandleShowTrailer}><svg className="img-fluid" width="182px" height="54px" viewBox="0 0 182 54">
                                    <path fillRule="evenodd" fill="rgb(211, 0, 0)" d="M24.474,53.092 L0.407,29.126 L0.407,0.367 L181.222,0.367 L181.222,53.092 L24.474,53.092 Z" />
                                    <text transform="translate(46 34)" fontSize="1.6rem" fontFamily="Kanit" fontStyle="italic" fill="white">TRAILER</text>
                                </svg></a>
                                <a className="svg-btn svg-mobile" href="#wishlist" title="Wishlist"><svg className="img-fluid desktop" width="182px" height="54px" viewBox="0 0 182 54">
                                    <path fillRule="evenodd" fill="rgb(211, 0, 0)" d="M0.407,0.367 L181.222,0.367 L181.222,53.092 L0.407,53.092 L0.407,0.367 Z" />
                                    <text transform="translate(38 34)" fontSize="1.6rem" fontFamily="Kanit" fontStyle="italic" fill="white">WISHLIST</text>
                                </svg><svg className="img-fluid mobile" width="182px" height="54px" viewBox="0 0 182 54">
                                        <path fillRule="evenodd" fill="rgb(211, 0, 0)" d="M156.637,52.819 L0.215,52.819 L0.215,0.367 L180.654,0.367 L180.654,28.977 L156.637,52.819 Z" />
                                        <text transform="translate(34 34)" fontSize="1.6rem" fontFamily="Kanit" fontStyle="italic" fill="white">WISHLIST</text>
                                    </svg></a>
                                <a className="svg-btn svg-mobile bottom" href="#signup" title={t('subscribe.action')}><svg className="img-fluid desktop" width="182px" height="54px" viewBox="0 0 182 54">
                                    <path fillRule="evenodd" fill="rgb(211, 0, 0)" d="M156.637,52.819 L0.215,52.819 L0.215,0.367 L180.654,0.367 L180.654,28.977 L156.637,52.819 Z" />
                                    <text transform="translate(43 34)" fontSize="1.6rem" fontFamily="Kanit" fontStyle="italic" fill="white">SIGN UP</text>
                                </svg><svg className="img-fluid mobile" width="207px" height="54px" viewBox="0 0 207 54">
                                        <path fillRule="evenodd" fill="rgb(211, 0, 0)" d="M206.594,29.097 L182.524,53.043 L181.222,53.042 L181.222,53.092 L24.474,53.092 L0.407,29.126 L0.407,0.367 L1.000,0.367 L1.000,0.000 L206.000,0.000 L206.000,0.347 L206.594,0.347 L206.594,29.097 Z" />
                                        <text transform="translate(58 34)" fontSize="1.6rem" fontFamily="Kanit" fontStyle="italic" fill="white">SIGN UP</text>
                                    </svg></a>
                            </div>
                        </div>
                        <img className="img-fluid w-100" src={DividerBar} alt={altTitle} />
                    </div>
                </div>
            </section>
            <section id="story">
                <div id="story-zoom" className="bkg-zoom" style={{ backgroundImage: `url(${BkgImageStory})` }}></div>
                <div className="bars-wrapper">
                    <img id="bar-1" className="bars" src={require(`../assets/bar_1.png`)} alt={altTitle} />
                    <img id="bar-2" className="bars" src={require(`../assets/bar_2.png`)} alt={altTitle} />
                    <img id="bar-3" className="bars" src={require(`../assets/bar_3.png`)} alt={altTitle} />
                    <img id="story-left-bar" className="sidebar" src={StorySideBar} alt={altTitle} />
                    <img id="story-right-bar" className="sidebar" src={StorySideBar} alt={altTitle} />
                </div>
                <div className="overlay">
                    <div className="container">
                        <h2 className="heading inview animated mb-4">{t('story.title')}</h2>
                        <p className="inview animated delay-2">{t('story.content')}</p>
                        <div className="center-bar inview animated delay-3">
                            <img className="img-fluid" src={CenterDivider2Svg} alt={altTitle} />
                        </div>
                    </div>
                </div>
            </section>
            <section id="media">
                <div className="media-wrapper">
                    <h2><span className="heading inview animated">{t('media.title')}</span></h2>
                    <div className="swiper-block">
                        <Swiper
                            spaceBetween={10}
                            slidesPerView={3}
                            loop
                            speed={800}
                            navigation={{
                                nextEl: '.swiper-next-arrow',
                                prevEl: '.swiper-prev-arrow',
                            }}
                            modules={[Navigation]}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                499: {
                                    slidesPerView: 2,
                                },
                                768: {
                                    slidesPerView: 3,
                                },
                            }}
                        >
                            {mediaAssets.map(function (slide) {
                                return (
                                    <SwiperSlide key={slide.id}>
                                        <a
                                            href={require(`../assets/screens/${slide.path}`)}
                                            className="glightbox"
                                            data-gallery="Screenshots"
                                            data-type="image"
                                            data-glightbox={`description:<div class="d-flex justify-content-center">${(slide.mobile) ? `<a class="btn btn-shape" href="download.php?asset=${slide.mobile}">MOBILE</a>` : ``}${(slide.desktop) ? `<a class="btn btn-shape" href="download.php?asset=${slide.desktop}">DESKTOP</a>` : ``}</div>`}
                                        ><img
                                                className="img-fluid"
                                                src={require(`../assets/screens/${slide.thumb}`)}
                                                alt={slide.title}
                                                title={slide.title} /></a>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                        <div className="swiper-navigation">
                            <div className="swiper-arrows swiper-prev-arrow">
                                <img className="img-fluid" src={SwiperArrow} alt={altTitle} />
                            </div>
                            <div className="swiper-arrows swiper-next-arrow">
                                <img className="img-fluid" src={SwiperArrow} alt={altTitle} />
                            </div>
                        </div>
                    </div>
                </div>
                <img className="divider img-fluid w-100" src={DividerBar} alt={altTitle} />
            </section>
            <section id="wishlist">
                <div id="wishlist-zoom" className="bkg-zoom" style={{ backgroundImage: `url(${BkgImageWishlist})` }}></div>
                <div className="bars-wrapper">
                    <img className="sidebar" src={WishSideBar} alt={altTitle} />
                    <img className="sidebar" src={WishSideBar} alt={altTitle} />
                </div>
                <div className="layer">
                    <div className="container">
                        <div className="wishlist-selection">
                            <div className="wish-block">
                                <img className="img-fluid" src={BkgImageWish} alt={altTitle} />
                            </div>
                            <h2 className="heading inview animated">{t('wish.title')}</h2>
                            <div className="wrapper d-flex align-items-center justify-content-center">
                                <span title="STEAM">{t('wish.steam') !== "" ? (<a href={t('wish.steam')} target="_blank" rel="noopener noreferrer" title="STEAM"><SteamLogo /></a>) : <SteamLogo />}</span>
                                <span title="EPIC Games" className="epic">{t('wish.epic') !== "" ? (<a href={t('wish.epic')} target="_blank" rel="noopener noreferrer" title="EPIC Games"><EpicLogo /></a>) : <EpicLogo />}</span>
                                <span title="Playstation" className="ps">{t('wish.playstation') !== "" ? (<a href={t('wish.playstation')} target="_blank" rel="noopener noreferrer" title="Playstation"><PSLogo /></a>) : <PSLogo />}</span>
                                <span title="XBOX">{t('wish.xbox') !== "" ? (<a href={t('wish.xbox')} target="_blank" rel="noopener noreferrer" title="XBOX"><XBoxLogo /></a>) : <XBoxLogo />}</span>
                            </div>
                            {t('wish.playstation') === "" && (<h4 className="soon">Coming Soon</h4>)}
                        </div>
                    </div>
                </div>
            </section>

            <section id="signup">
                <div className="bar-line"><img className="img-fluid" src={BarLine} alt={altTitle} /></div>
                <div id="signup-zoom" className="bkg-zoom" style={{ backgroundImage: `url(${BkgImageSign})` }}></div>
                <div className="signup-wrapper">
                    <h3 className="heading inview animated delay-2">{t('subscribe.title')}</h3>
                    <h4 className="mt-4 inview animated delay-2"><span>{t('subscribe.content')}</span></h4>
                    <div className="position-relative inview animated delay-3">
                        <MailchimpSubscribe render={hooks => (
                            <>
                                <form className="row"
                                    onSubmit={e => {
                                        e.preventDefault();
                                        hooks.subscribe({ EMAIL: email, tags: 32087 });
                                    }}
                                >
                                    <div className="col-auto shape">
                                        <svg className="img-fluid desktop" width="411px" height="63px" viewBox="0 0 411 63">
                                            <path fillRule="evenodd" strokeWidth="2px" stroke="rgb(127, 0, 0)" fill="rgb(0, 0, 0)" d="M29.475,57.725 L5.407,33.758 L5.407,5.000 L405.222,5.000 L405.222,57.725 L29.475,57.725 Z" />
                                        </svg>
                                        <input id="email" type="email" className="form-control" required value={email} onChange={HandleOnChange} placeholder={t('subscribe.placeholder')} />
                                    </div>
                                    <div className="col-auto">
                                        <button type="submit" className="svg-btn svg-mobile bottom" title={t('subscribe.action')}><svg className="img-fluid desktop" width="182px" height="54px" viewBox="0 0 182 54">
                                            <path fillRule="evenodd" fill="rgb(211, 0, 0)" d="M156.637,52.819 L0.215,52.819 L0.215,0.367 L180.654,0.367 L180.654,28.977 L156.637,52.819 Z" />
                                            <text transform="translate(43 34)" fontSize="1.6rem" fontFamily="Kanit" fontStyle="italic" fill="white">SIGN UP</text>
                                        </svg><svg className="img-fluid mobile" width="209px" height="54px" viewBox="0 0 209 54">
                                                <path fillRule="evenodd" fill="rgb(211, 0, 0)" d="M184.524,53.094 L27.781,53.094 L27.781,53.092 L24.474,53.092 L0.407,29.126 L0.407,0.367 L181.222,0.367 L181.222,0.375 L208.594,0.375 L208.594,29.125 L184.524,53.094 Z" />
                                                <text transform="translate(58 34)" fontSize="1.6rem" fontFamily="Kanit" fontStyle="italic" fill="white">SIGN UP</text>
                                            </svg></button>
                                    </div>
                                </form>
                                {hooks.status === 'error' && (
                                    <div className="alert alert-danger">{t('subscribe.error')} {hooks.message.replace(/0 - /g, '')}</div>
                                )}
                                {hooks.status === 'success' && (
                                    <div className="alert alert-success">{hooks.message}</div>
                                )}
                            </>
                        )}
                            url={mailChimpUrl}
                        /></div>
                </div>

                <div className="position-relative mt-5"><img className="divider img-fluid w-100" src={DividerBar} alt={altTitle} /></div>
            </section>
            <Modal show={showTrailer} onHide={HandleCloseTrailer} onEntered={PlayTrailer}
                size="xl"
                centered>
                <Modal.Header>
                    <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={HandleCloseTrailer}></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="ratio ratio-16x9">
                        <div id="player" />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}; 