import React from "react";
import { withTranslation } from 'react-i18next';
import LogoAlt from '../assets/logo_alt.png';
import TripWire from '../assets/tripwire.png';
import ESRB from '../assets/RP-FULL_TeenPlus.gif';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.getCookie('privacy') ? 1 : 0
        };
        this.year = new Date().getFullYear();
    }
    getCookie = (cname) => {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    };
    SetCookie = (cname) => {
        const d = new Date();
        d.setTime(d.getTime() + (60 * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=accept;" + expires + ";path=/";
    };
    AcceptCookie = () => {
        this.SetCookie('privacy');
        this.setState({ show: 1 });
    };
    CloseCookie = () => {
        this.setState({ show: 1 });
    }
    render() {
        const { t } = this.props;
        return (
            <>
                <footer>
                    <div className="container-fluid">
                        <div className="d-md-flex justify-content-between">
                            <div>
                                <a href="/" className="brand-logo"><img src={LogoAlt} className="img-fluid" alt="Nightfall - Home" /></a>
                                <div className="legal d-none d-md-block">{t('footer.copyright', { year: this.year })}</div>
                            </div>
                            <div className="legal-links text-end">
                                <a className="me-1 d-none d-md-block" href={t('footer.privacylink')} target="_blank" rel="noopener noreferrer" title="Privacy Policy">Privacy Policy</a>
                                <div className="legal-logos mt-3 d-flex align-items-center justify-content-between">
                                    <a className="item me-2" href="https://www.tripwireinteractive.com/" target="_blank" rel="noopener noreferrer" title="TRIPWIRE"><img className="img-fluid" src={TripWire} alt="TRIPWIRE" /></a>
                                    <span className="item"><img className="img-fluid border" src={ESRB} alt="ESRB" /></span>
                                </div>
                            </div>
                        </div>
                        <div className="legal mt-4 d-md-none">{t('footer.copyright', { year: this.year })} <a className="me-1" href={t('footer.privacylink')} target="_blank" rel="noopener noreferrer" title="Privacy Policy">Privacy Policy</a></div>
                    </div>
                </footer>
                {!this.state.show && (
                    <div id="cookie-bar" className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                            <div
                                dangerouslySetInnerHTML={{ __html: t('footer.cookie') }}
                            />
                            <button className="btn btn-theme sml ms-2" type="button" onClick={this.AcceptCookie.bind(this)}>OK</button>
                        </div>
                        <div id="close-cookie" className="img-fluid" onClick={this.CloseCookie.bind(this)}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <g fill="#c51e1e">
                                    <path d="M505.943,6.058c-8.077-8.077-21.172-8.077-29.249,0L6.058,476.693c-8.077,8.077-8.077,21.172,0,29.249C10.096,509.982,15.39,512,20.683,512c5.293,0,10.586-2.019,14.625-6.059L505.943,35.306C514.019,27.23,514.019,14.135,505.943,6.058z"></path><path d="M505.942,476.694L35.306,6.059c-8.076-8.077-21.172-8.077-29.248,0c-8.077,8.076-8.077,21.171,0,29.248l470.636,470.636c4.038,4.039,9.332,6.058,14.625,6.058c5.293,0,10.587-2.019,14.624-6.057C514.018,497.866,514.018,484.771,505.942,476.694z"></path>
                                </g>
                            </svg>
                        </div>
                    </div>
                )}
            </>
        );
    }
}
export default withTranslation()(Footer);