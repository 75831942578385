import Footer from "./components/Footer";
import { Home } from "./views/Home";
import './styles/App.scss';

function App() {
  return (
    <div className="main">
      <Home />
      <Footer />
    </div>
  );
}

export default App;
